<script setup lang="ts">
import {usePage} from "@inertiajs/inertia-vue3";
import {ref} from "vue";
import {Inertia} from "@inertiajs/inertia";

const props = defineProps<{
	text: string,
	forceLoading: boolean,
	widthHeightBorder: string
}>();
const page = usePage();

const loading = ref(false);

Inertia.on('start', () => loading.value = true);
Inertia.on('finish', () => loading.value = false);

</script>
<template>
	<div v-if="loading || forceLoading" aria-label="Loading..." role="status"
	     class="flex flex-col items-center bg-white px-8 py-2 rounded-xl text-center"
	     style="  box-shadow: 0 0 5px gray;">
		<div class="relative flex justify-center items-center w-12 h-12">
			<div :style="'border-color:'+page.props.value.primaryColor + '; border-top-color:#f1f5f9 !important;'"
			     :class="widthHeightBorder ?? 'w-12 h-12 border-8'"
			     class=" border-primary border-solid rounded-full animate-spin"
			></div>
		</div>
		<span class="sr-only">Loading...</span>
		<span class="text-xs font-medium text-gray-500 mx-auto mt-2">{{ text ?? 'Laden' }}</span>
	</div>
</template>

